import { config } from '@/config'
import { WEBSOCKET_CONNECT_DASHBOARD } from '@/const/webSocket.const'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { v4 } from 'uuid'
import React, {
	createContext,
	useEffect,
	useRef,
	useState,
	ReactNode,
	useContext
} from 'react'

interface IWSData {
	action: string
	payload: any
}

// Определяем интерфейс для контекста
interface WebSocketContextType {
	data?: IWSData[]
	isConnected: boolean
	close: () => void
	send: (message: string | Object) => void
	connect: () => void // Добавляем метод для подключения
}

// Создаем контекст с начальным значением null
const WebSocketContext = createContext<WebSocketContextType | null>(null)

export const WebSocketProvider: React.FC<{ children: ReactNode }> = ({
	children
}) => {
	const queryClient = useQueryClient()

	const [isConnected, setIsConnected] = useState<boolean>(false)
	const websocketRef = useRef<WebSocket | null>(null)

	const connect = () => {
		if (!websocketRef.current && config.websocket_url) {
			websocketRef.current = new WebSocket(config.websocket_url)

			websocketRef.current.onopen = () => {
				console.log('connected')
				setIsConnected(true)
				send({
					action: 'connect',
					payload: {
						device_id: v4(),
						device_model: 5,
						device_type: 7
					}
				})
			}

			websocketRef.current.onclose = () => {
				console.log('WebSocket connection closed')
				setIsConnected(false)
				websocketRef.current = null
			}

			websocketRef.current.onerror = error => {
				console.error('WebSocket error:', error)
			}

			websocketRef.current.onmessage = event => {
				try {
					const data = JSON.parse(event.data)
					console.log(data)

					queryClient.setQueriesData(
						{ queryKey: [WEBSOCKET_CONNECT_DASHBOARD] },
						// @ts-ignore
						(oldData: IWSData[]) => {
							return oldData ? [...oldData, data] : [data]
						}
					)
				} catch (error) {
					console.error('Error parsing message data:', error)
				}
			}
		}
	}

	const send = (message: string | Object) => {
		if (
			websocketRef.current &&
			websocketRef.current.readyState === WebSocket.OPEN
		) {
			websocketRef.current.send(JSON.stringify(message))
		} else {
			console.error('WebSocket is not open. Unable to send message.')
		}
	}

	const close = () => {
		websocketRef.current?.close()
	}

	return (
		<WebSocketContext.Provider value={{ isConnected, close, connect, send }}>
			{children}
		</WebSocketContext.Provider>
	)
}

// Хук для использования контекста
export const useWebSocketHook = (): WebSocketContextType => {
	const context = useContext(WebSocketContext)

	if (!context) {
		throw new Error('useWebSocket must be used within a WebSocketProvider')
	}

	// Проверяем подключение и подключаемся, если необходимо
	if (!context.isConnected) {
		context.connect()
	}

	const { data } = useQuery<IWSData[]>({
		queryKey: [WEBSOCKET_CONNECT_DASHBOARD],
		initialData: []
	})

	return { ...context, data }
}
