import { Button, Progress, Spin, notification } from 'antd'
import { FC, useEffect, useState } from 'react'
import { ReloadOutlined } from '@ant-design/icons'
import Typewriter from '@/TypeWritter'
import { useGetCheckReportedAllQuery } from '@/hooks/checkReported.hook'
import { useGetCollectingSessionQuery } from '@/hooks/collectingSession.hook'

const CollectStartProgress: FC = () => {
	const [closed, setClosed] = useState(false)
	const { data, isError, isSuccess, refetch, isLoading } =
		useGetCheckReportedAllQuery()

	const session = useGetCollectingSessionQuery()

	useEffect(() => {
		notification.destroy()
		if (isError || !isSuccess || data.progress === 100 || closed) {
			return
		}

		if (isSuccess && session.isSuccess && !session?.data?.started) {
			notification.info({
				type: 'info',
				message: (
					<div className='flex items-center gap-3'>
						<p>Ожидайте</p>
					</div>
				),
				icon: <Spin spinning></Spin>,
				duration: 0,
				onClose: () => setClosed(true),
				placement: 'bottomRight',
				description: (
					<div>
						<div>
							Сборка инициализируется
							<Typewriter infinite delay={250} text='....' />
						</div>
						<div>
							<Progress percent={data?.progress} />
						</div>
						<div>
							<Button
								loading={isLoading}
								onClick={() => refetch()}
								icon={<ReloadOutlined rev={''} />}>
								Обновить
							</Button>
						</div>
					</div>
				)
			})
		}
	}, [isError, isSuccess, data, data?.progress, session.isSuccess])

	return <></>
}

export default CollectStartProgress
