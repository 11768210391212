import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import user from '../store/user'
import { config } from '../config'

interface ApiErrorResponse {
	details?: string
	detail?: string
}

export const errorHandler = async (e: any, func: any) => {
	console.error('Error in request')
	if (window.location.pathname === '/login') return
	if ((e.response.status || 0) === 401) {
		const refresh = localStorage.getItem('refresh_token')
		try {
			const { data } = await $host.post('api/login/refresh/', { refresh })
			localStorage.setItem('access_token', data.access)
			func()
		} catch (e) {
			user.Authorization = false
		}
	}
}

// Функция для обновления токена
const refreshAccessToken = async (): Promise<string | null> => {
	const refresh = localStorage.getItem('refresh_token')
	if (!refresh) return null

	try {
		const { data } = await $host.post('api/login/refresh/', { refresh })
		localStorage.setItem('access_token', data.access)
		return data.access
	} catch (refreshError) {
		console.error('Ошибка обновления токена:', refreshError)
		return null
	}
}

// Создаем два запроса
// один обычный, второй с токеном
const $host = axios.create({
	baseURL: config.base_url,
	withCredentials: false
})

// Создаем два запроса
// один обычный, второй с токеном
const $authHost = axios.create({
	baseURL: config.base_url,
	withCredentials: false
})

// Создаем функцию добавляющую конфигу токен
const authInterceptor = (config: AxiosRequestConfig<any>) => {
	if (!config || !config.headers) {
		throw Error('Axios: не задан config')
	}
	const token = localStorage.getItem('access_token')
	if (!token) {
		return config
	}
	config.headers.Authorization = `Bearer ${token}`
	return config
}

// Навешиваем функцию с изменением конфига
// на "отлавливатель" запросов
$authHost.interceptors.request.use(authInterceptor)

$authHost.interceptors.response.use(
	response => response,
	async (error: AxiosError<ApiErrorResponse>) => {
		const originalRequest = error.config
		const isNotLoginPage = window.location.pathname !== '/login'
		const isNotRackPage = !window.location.pathname.includes('/rack')
		// Проверяем, есть ли ошибка 401 (не авторизован)
		if (error.response?.status === 401 && isNotLoginPage && isNotRackPage) {
			const newAccessToken = await refreshAccessToken()

			if (newAccessToken) {
				// Проверяем, существует ли headers
				if (originalRequest.headers) {
					originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
				}
				// Повторяем оригинальный запрос с новым токеном
				return $authHost(originalRequest)
			} else {
				// Если не удалось обновить токен, редирект на страницу авторизации
				window.location.href = '/login'
			}
		}

		const modifiedError = error
		let errorMessage = 'Произошла неизвестная ошибка'
		if (error.response?.data?.details) {
			errorMessage = error.response.data.details
		}
		if (error.response?.data?.detail) {
			errorMessage = error.response.data.detail
		}
		modifiedError.message = errorMessage
		throw modifiedError
	}
)

$host.interceptors.response.use(
	response => response,
	error => {
		const modifiedError = error
		let errorMessage = 'Произошла неизвестная ошибка'
		if (error.response?.data?.details) {
			errorMessage = error.response.data.details
		}
		modifiedError.message = errorMessage
		throw modifiedError
	}
)

export { $host, $authHost }
