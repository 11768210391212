export const config = {
	base_url: window.location.origin.includes('localhost')
		? process.env.REACT_APP_API_URL
		: window.location.origin + '/',
	websocket_url: window.location.origin.includes('localhost')
		? process.env.REACT_APP_WEBSOCKET_URL
		: (window.location.origin.includes('https://') ? 'wss://' : 'ws://') +
			window.location.host +
			'/websocket/'
}
