import { BrowserRouter } from 'react-router-dom'
import './App.css'
import { useMount } from './hooks/useMount'
import User from './store/user'
import SettingsCollectSystem from './store/Settings/SettingsCollectSystem/SettingsCollectSystem'
import ModalContainer from 'react-modal-promise'
import { Suspense, lazy, useState } from 'react'
import { FetchStages } from './types'
import { notification } from 'antd'
import Company from './store/Company/Company'
import PageLoader from './components/PageLoader'
import CollectStartProgress from './components/Notification/CollectStartProgress'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WebSocketProvider } from './components/TestEqupmentWidget/hooks/useWebsocketHook'
const ScreenSplit = lazy(() => import('./components/ScreenSplit/ScreenSplit'))
const AppRouter = lazy(() => import('./components/AppRouter'))

const App = () => {
	const queryClient = new QueryClient()

	const [api, context] = notification.useNotification()
	const [isLoading, setIsLoading] = useState(FetchStages.waiting)

	useMount(() => {
		firstFetch()
	})

	const firstFetch = async () => {
		setIsLoading(FetchStages.start)
		User.handleLogin()

		// Handlers
		setIsLoading(FetchStages.end)
		Company.handleCompany()
		SettingsCollectSystem.HandleReduceCollect()
	}
	return (
		// <AdBanner>

		<QueryClientProvider client={queryClient}>
			<Suspense fallback={<PageLoader />}>
				{isLoading === FetchStages.start ||
				isLoading === FetchStages.waiting ? (
					<PageLoader />
				) : (
					<ScreenSplit>
						<WebSocketProvider>
							<BrowserRouter>
								<AppRouter />
								{context}
								<CollectStartProgress />
								<ModalContainer />
							</BrowserRouter>
						</WebSocketProvider>
					</ScreenSplit>
				)}
			</Suspense>
		</QueryClientProvider>
		// </AdBanner>
	)
}
export default App
