import User from '../store/user'
import { $authHost, $host } from '.'
import jwtDecode from 'jwt-decode'
import { IUser } from '../types/user'
import { notification } from '../utils/notification'
import Company from '@/store/Company/Company'
import Theme from '@/store/Theme'
import { Language } from '@/utils/langSwitch'
import SettingsCollectSystem from '@/store/Settings/SettingsCollectSystem/SettingsCollectSystem'
import AuthStore from '@/store/Auth.store'

interface IAuthorizationProps {
	username: string
	password: string
}

export const Authorization = async ({
	username,
	password
}: IAuthorizationProps) => {
	try {
		if (User.fetchStatus === 'fetch') return
		User.fetchStatus = 'fetch'
		const { data } = await $host.post('api/login/', { username, password })
		localStorage.setItem('access_token', data.access)
		localStorage.setItem('refresh_token', data.refresh)
		//@ts-ignore
		User.role = jwtDecode(data.access).role
		User.isAuth = true
		User.Authorization = true
		User.fetchStatus = 'success'
		setTimeout(() => {
			User.fetchStatus = 'none'
		}, 500)
		if (User.rememberMe) {
			const user = await User.getCurrentUser()
			User.addLogUsersList(user)
		}
		Company.handleCompany()
		Theme.handleThemeChange()
		Language.handleLanguage()
		SettingsCollectSystem.HandleReduceCollect()
	} catch (e) {
		User.fetchStatus = 'error'
		setTimeout(() => {
			User.fetchStatus = 'none'
		}, 500)
		User.isAuth = false
		User.Authorization = true
	}
}

export const updateToken = async (refresh: string | undefined | null) => {
	try {
		User.loginStatus = 'fetch'
		if (!refresh) {
			return
		}
		const res = await $host.post('api/login/refresh/', { refresh })
		localStorage.setItem('access_token', res.data.access)
		localStorage.setItem('refresh_token', refresh)
		//@ts-ignore
		const { user_id, role } = jwtDecode(res.data.access)
		const { data } = await $authHost.get<IUser>(`api/user/${user_id}`)
		AuthStore.isAdmin = !!data.is_superuser
		User.loginStatus = 'success'
		User.role = role
		User.UserData = data
		setTimeout(() => {
			User.loginStatus = 'none'
		}, 500)
		//@ts-ignore
		User.isAuth = true
		User.Authorization = true
	} catch (e) {
		console.log(e)
		User.loginStatus = 'error'
		setTimeout(() => {
			User.loginStatus = 'none'
		}, 500)
		User.isAuth = false
		User.Authorization = false
	}
}
