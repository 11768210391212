import { useState, useEffect, FC } from 'react'

interface ITypewriterProps {
	text: string
	delay: number
	infinite: boolean
}

const Typewriter: FC<ITypewriterProps> = ({ text, delay, infinite }) => {
	const [currentText, setCurrentText] = useState('')
	const [currentIndex, setCurrentIndex] = useState(0)

	useEffect(() => {
		let timeout: any

		if (currentIndex <= text.length - 1) {
			timeout = setTimeout(() => {
				setCurrentText(prevText => prevText + text[currentIndex])
				setCurrentIndex(prevIndex => prevIndex + 1)
			}, delay)
		} else if (infinite) {
			// ADD THIS CHECK
			setCurrentIndex(0)
			setCurrentText('')
		}

		return () => clearTimeout(timeout)
	}, [currentIndex, delay, infinite, text])

	return <span>{currentText}</span>
}

export default Typewriter
