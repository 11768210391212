import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'

configure({ enforceActions: 'never' })
class Auth {
	isAdmin = false

	constructor() {
		makeAutoObservable(this)
	}
}

export default new Auth()
