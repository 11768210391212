import { QUERY_CHECK_REPORTED_ALL_KEY } from '@/const/checkReported.const'
import checkReportedService from '@/services/checkReported.service'
import { useQuery } from '@tanstack/react-query'
import { ICheckReportedParams } from '@/types/checkReported.types'

export const useGetCheckReportedAllQuery = (params?: ICheckReportedParams) =>
	useQuery({
		queryKey: [QUERY_CHECK_REPORTED_ALL_KEY],
		select: data => data.data,
		refetchInterval: 5000,
		staleTime: 0,
		queryFn: async () => checkReportedService.getAll()
	})
