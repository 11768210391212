import { $authHost } from '@/API'
import {
	ICheckReported,
	ICheckReportedParams
} from '@/types/checkReported.types'

class CheckReportedService {
	getAll = (params?: ICheckReportedParams) => {
		return $authHost.get<ICheckReported>('api/check_init_picking/', {
			params
		})
	}
}

export default new CheckReportedService()
