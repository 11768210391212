import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import LogotypeWithoutLink from './LogotypeWithoutLink'

const PageLoader = () => {
	return (
		<div className='h-screen w-screen flex flex-col gap-5 justify-center items-center'>
			<LogotypeWithoutLink className='w-96' />
			<Spin size='large'></Spin>
		</div>
	)
}

export default observer(PageLoader)
